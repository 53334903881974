<template>
  <div
    class="bg-black h-full fixed top-0 left-0 w-full lg:w-[--nav-left-width] z-10 lg:z-20 transition-transform overflow-y-scroll scrollbar-hide nav-left p-2"
    :class="[
      { '-translate-x-full lg:-translate-x-full' : ['hidden', 'mini'].includes(navState) },
      { 'mt-[76px] pb-[80px] lg:mt-0': ['full'].includes(navState) }
    ]"
  >
    <div class="flex justify-center flex-wrap flex-col items-center">
      <NavigationSectionOldCompetitions v-if="isUserLogged"/>
      <NavigationSectionOldGamesInstantWin v-if="isUserLogged"/>
      <NavigationSectionOldGamesLive v-if="isUserLogged"/>
      <NavigationSectionOldSports v-if="isUserLogged && sportsBettingProviders[0].enabled"/>
      <NavigationSectionOldCryptoFutures v-if="isUserLogged && nuxtApp.$config.public.TRADING_ENABLED"/>
      <NavigationSectionOldVip v-if="isUserLogged && nuxtApp.$config.public.SHOW_VIP"/>
      <NavigationSectionOldPromotions v-if="isUserLogged"/>
      <NavigationSectionOldGames v-if="isUserLogged"/>
      <NavigationSectionOldCommunity v-if="isUserLogged"/>
      <NavigationSectionOldBridge v-if="isUserLogged"/>
      <NavigationSectionOldRaf v-if="isUserLogged"/>
      <NavigationSectionOldUser v-if="isUserLogged"/>
      <NavigationSectionOldSupport v-if="isUserLogged"/>
      <NavigationSectionOldSocials/>
      <PaymentsBanner v-if="isUserLogged" small/>
      <SwitchCompetitionValue
        id="expandedCurrencyToggle"
        class="my-2"
        small
      />
      <UserFlagSwitch small/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useSportsBettingStore } from '@/store/sportsBetting';

const uiStore = useUiStore();
const {
  navState,
} = storeToRefs(uiStore);

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const nuxtApp = useNuxtApp();

const sportsBettingStore = useSportsBettingStore();
const { sportsBettingProviders, } = storeToRefs(sportsBettingStore);
</script>

<style lang="scss" scoped>
.navigation-section {

  background: #0F1115;
  margin-bottom: 0.5em;
}
</style>


