<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative"
      :class="{ 'active': item.isActive }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        height="20"
        width="20"
        class="icon"
      />
      <div v-if="navState === 'full'" class="label">
        {{ item.label }}
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const items = computed(() => [
  {
    icon: 'dice',
    label: 'Instant Win',
    isActive: route.path === '/games/',
    action: () => sportsBettingRedirect('/games/'),
  },
]);
</script>

<style lang="scss" scoped>
.navigation-section {
  &.collapsed {
    background: unset;

    .nav-button {
      padding: 9px 10px;

      &:nth-child(even) {
        margin: 0.25em 0;
      }

      .svg-icon {
        margin: auto;
      }
    }
  }

  .nav-button {
    color: #FFC100;

    padding: 0.5em 1em;

    .label {
      margin-left: 0.5em;
    }
  }
}
</style>
