<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div
      :class="{ 'active': category.isActive || (!dropdownEnabled) }"
      class="flex items-stretch drop-down"
    >
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        :class="{ 'cursor-not-allowed' : !isUserLogged}"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="label">
          {{ category.label }}
        </div>
        <NavigationToolTip :label="category.label"/>
      </button>
      <button
        v-if="dropdownEnabled && !disabled"
        :class="{ 'cursor-not-allowed' : !isUserLogged}"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="toggleDropdown"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180 text-white': !sportsNavIsCollapsed }"
        />
      </button>
    </div>
    <div v-if="!sportsNavIsCollapsed && !disabled" class="dropdown-content mt-1">
      <button
        v-for="item in items"
        :key="item.icon"
        class="flex items-center nav-button nav-sub rounded-md w-full relative pl-2"
        :class="{ 'active': item.isActive }"
        size="2xs"
        @click.prevent.stop="item.action()"
      >
        <SvgIcon
          :icon="item.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="label">
          {{ item.label }}
        </div>
        <NavigationToolTip :label="item.label"/>
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useSportsBettingStore } from '@/store/sportsBetting';
import { useNavigation } from '@/composables/useNavigation';
import { useAuthStore } from '@/store/auth';

const uiStore = useUiStore();
const { navState, sportsNavIsCollapsed, } = storeToRefs(uiStore);
const { closeMobileNav, } = uiStore;

const sportsBettingStore = useSportsBettingStore();
const { redirectToPath, triggeredFromNav, } = storeToRefs(sportsBettingStore);

const authStore = useAuthStore();
const { isUserLogged, } = storeToRefs(authStore);

const { handleBasicRedirectTo, } = useNavigation();

const route = useRoute();

const disabled = computed(() => useRuntimeConfig().public.SPORTS_DISABLED === true);

const sportsPathsRegex = /sports/g;

const dropdownEnabled = computed(() => navState.value === 'full');
const isSportsPath = computed(() => sportsPathsRegex.test(route.path));
const category = computed(() => {
  return {
    icon: 'sports',
    label: 'Sports',
    isActive: !sportsNavIsCollapsed.value,
    action: () => {
      toggleDropdown();
      redirectToPath.value = '/';
      triggeredFromNav.value = true;
      handleBasicRedirectTo('/sports');
    },
  };
});
const items = computed(() => [
  {
    icon: 'live',
    label: 'Live',
    isActive: redirectToPath.value.includes('live') && route.path.includes('sports'),
    action: () => handleRedirectTo('live'),
  },
  {
    icon: 'bets',
    label: 'My Bets',
    isActive: redirectToPath.value.includes('bets') && route.path.includes('sports'),
    action: () => handleRedirectTo('bets'),
  },
  {
    icon: 'favorites',
    label: 'Favorites',
    isActive: redirectToPath.value.includes('favorites') && route.path.includes('sports'),
    action: () => handleRedirectTo('favorites'),
  },
  {
    icon: 'soccer',
    label: 'Soccer',
    isActive: redirectToPath.value.includes('soccer-1') && route.path.includes('sports'),
    action: () => handleRedirectTo('soccer-1'),
  },
  {
    icon: 'basketball',
    label: 'Basketball',
    isActive: redirectToPath.value.includes('basketball-2') && route.path.includes('sports'),
    action: () => handleRedirectTo('basketball-2'),
  },
  {
    icon: 'americanFootball',
    label: 'American Football',
    isActive: redirectToPath.value.includes('american-football-16') && route.path.includes('sports'),
    action: () => handleRedirectTo('american-football-16'),
  },
  {
    icon: 'tennis',
    label: 'Tennis',
    isActive: redirectToPath.value.includes('tennis-5') && route.path.includes('sports'),
    action: () => handleRedirectTo('tennis-5'),
  },
]);

function handleRedirectTo(path) {
  if (!isSportsPath.value) {
    handleBasicRedirectTo('/sports', {
      'bt-path': path,
    });
  }

  redirectToPath.value = path;
  triggeredFromNav.value = true;
  closeMobileNav();
}

function toggleDropdown() {
  if (isUserLogged.value) {
    sportsNavIsCollapsed.value = !sportsNavIsCollapsed.value;
  }
}
</script>

<style lang="scss" scoped>
.navigation-section {
  .category {
    color: white;

    &.active {
      color: white;
    }
  }

  .active {
    color: white;
  }
}
</style>
