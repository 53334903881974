<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div
      :class="{ 'active': category.isActive}"
      class="flex items-stretch drop-down"
    >
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="label text-white">
          {{ category.label }}
        </div>
        <NavigationToolTip :label="category.label"/>
      </button>
      <button
        v-if="dropdownEnabled"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="communityNavIsCollapsed = !communityNavIsCollapsed"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180 text-white': !communityNavIsCollapsed }"
        />
      </button>
    </div>
    <div v-if="!communityNavIsCollapsed && dropdownEnabled" class="dropdown-content mt-1">
      <template
        v-for="item in userMenuItems"
        :key="item.icon"
      >
        <button
          v-if="!item.disabled"
          class="flex items-center nav-button nav-sub rounded-md w-full relative"
          :class="{ 'active': item.isActive }"
          size="2xs"
          @click.prevent.stop="item.action()"
        >
          <SvgIcon
            :icon="item.icon"
            class="text-[#CBCBCB] icon"
            height="20"
            width="20"
          />
          <div v-if="navState === 'full'" class="label">
            {{ item.label }}
          </div>
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, communityNavIsCollapsed, } = storeToRefs(uiStore);

const { handleOpenNewTab, } = useNavigation();

const dropdownEnabled = computed(() => navState.value === 'full');
const category = computed(() => {
  return {
    icon: 'community',
    label: 'Community',
    isActive: !communityNavIsCollapsed.value,
    action: () => {
      communityNavIsCollapsed.value = !communityNavIsCollapsed.value;
    },
  };
});
const userMenuItems = computed(() => [
  {
    icon: 'discord',
    label: 'Discord',
    action: () => handleOpenNewTab('https://discord.gg/the-arena'),
  },
  {
    icon: 'twitter',
    label: 'X / Twitter',
    action: () => handleOpenNewTab('https://twitter.com/Meta_Winners'),
  },
  {
    icon: 'telegram',
    label: 'Telegram',
    action: () => handleOpenNewTab('https://t.me/metawinlfg'),
  },
]);
</script>
