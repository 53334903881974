<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div class="flex items-stretch">
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        :class="{ 'active': category.isActive || (!dropdownEnabled && isSportsPath) }"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          height="20"
          width="20"
          class="icon"
        />
        <div v-if="navState === 'full'" class="label">
          {{ category.label }}
        </div>
        <NavigationToolTip :label="category.label"/>
      </button>
      <button
        v-if="dropdownEnabled && !disabled"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="isCollapsed = !isCollapsed"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180': !isCollapsed }"
        />
      </button>
    </div>
    <div v-if="!isCollapsed && dropdownEnabled && !disabled" class="dropdown-content mt-1">
      <button
        v-for="item in items"
        :key="item.icon"
        class="flex items-center nav-button rounded-md w-full relative"
        :class="{ 'active': item.isActive }"
        size="2xs"
        @click.prevent.stop="item.action()"
      >
        <SvgIcon
          :icon="item.icon"
          height="20"
          width="20"
          class="icon"
        />
        <div v-if="navState === 'full'" class="label">
          {{ item.label }}
        </div>
        <NavigationToolTip :label="item.label"/>
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useSportsBettingStore } from '@/store/sportsBetting';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);
const { closeMobileNav, } = uiStore;

const sportsBettingStore = useSportsBettingStore();
const { redirectToPath, triggeredFromNav, } = storeToRefs(sportsBettingStore);

const { handleBasicRedirectTo, } = useNavigation();

const route = useRoute();

const disabled = computed(() => useRuntimeConfig().public.SPORTS_DISABLED === true);

const sportsPathsRegex = /sports/g;
const isCollapsed = ref(!route.fullPath.includes('sports'));

const dropdownEnabled = computed(() => navState.value === 'full');
const isSportsPath = computed(() => sportsPathsRegex.test(route.path));
const isCategoryActive = computed(() => {
  return (route.path.includes('sports') && (redirectToPath.value === '/sports' || redirectToPath.value === '/'))
    || (items.value.find(item => item.isActive) && isCollapsed.value);
});
const category = computed(() => {
  return {
    icon: 'sports',
    label: 'Sports',
    isActive: isCategoryActive.value,
    action: () => {
      isCollapsed.value = false;
      redirectToPath.value = '/';
      triggeredFromNav.value = true;
      handleBasicRedirectTo('/sports');
    },
  };
});
const items = computed(() => [
  {
    icon: 'live',
    label: 'Live',
    isActive: redirectToPath.value.includes('live'),
    action: () => handleRedirectTo('live'),
  },
  {
    icon: 'bets',
    label: 'My Bets',
    isActive: redirectToPath.value.includes('bets'),
    action: () => handleRedirectTo('bets'),
  },
  {
    icon: 'favorites',
    label: 'Favorites',
    isActive: redirectToPath.value.includes('favorites'),
    action: () => handleRedirectTo('favorites'),
  },
  {
    icon: 'soccer',
    label: 'Soccer',
    isActive: redirectToPath.value.includes('soccer-1'),
    action: () => handleRedirectTo('soccer-1'),
  },
  {
    icon: 'basketball',
    label: 'Basketball',
    isActive: redirectToPath.value.includes('basketball-2'),
    action: () => handleRedirectTo('basketball-2'),
  },
  {
    icon: 'americanFootball',
    label: 'American Football',
    isActive: redirectToPath.value.includes('american-football-16'),
    action: () => handleRedirectTo('american-football-16'),
  },
  {
    icon: 'tennis',
    label: 'Tennis',
    isActive: redirectToPath.value.includes('tennis-5'),
    action: () => handleRedirectTo('tennis-5'),
  },
]);

function handleRedirectTo(path) {
  if (!isSportsPath.value) {
    handleBasicRedirectTo('/sports', {
      'bt-path': path,
    });
  }

  redirectToPath.value = path;
  triggeredFromNav.value = true;
  closeMobileNav();
}

watch(
  () => route.fullPath,
  (newVal) => {
    isCollapsed.value = !newVal.includes('sports');
  }
);
</script>

<style lang="scss" scoped>
.navigation-section {

  &.collapsed {
    background: unset;

    .nav-button {
      padding: 9px 10px;

      &:nth-child(even) {
        margin: 0.25em 0;
      }

      .svg-icon {
        margin: auto;
      }
    }
  }

  .category {
    color: #00EA69;

    &.active {
      color: white;
    }
  }

  .active {
    color: white;
  }

  .nav-button {
    padding: 0.5em 1em;

    .label {
      margin-left: 0.5em;
    }
  }
}
</style>
