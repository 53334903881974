<template>
  <div
    v-if="navState === 'mini'"
    v-tippy="{ content: label, placement: 'right', disabled: !navState === 'mini' }"
    class="absolute w-full h-full"
  />
</template>
<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';

defineProps({
  label: {
    type: String,
    required: true,
  },
});

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);
</script>
