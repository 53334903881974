<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div class="flex items-stretch">
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        :class="{ 'active': category.isActive }"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          height="20"
          width="20"
          class="icon"
        />
        <div v-if="navState === 'full'" class="label">
          {{ category.label }}
          <span class="px-1 py-0.5 text-2xs rounded-md font-600 bg-blue-500/20">
            beta
          </span>
        </div>
        <NavigationToolTip label="Crypto Futures"/>
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const route = useRoute();

const {
  handleBasicRedirectTo,
} = useNavigation();

const uiStore = useUiStore();

const {
  navState,
} = storeToRefs(uiStore);

const category = computed(() => {
  return {
    icon: 'trading',
    label: 'HODL',
    isActive: route.path === '/trading/hodl/',
    action: () => handleBasicRedirectTo('/trading/hodl'),
  };
});
</script>

<style lang="scss" scoped>
.navigation-section {

  &.collapsed {
    background: unset;

    .nav-button {
      padding: 9px 10px;

      &:nth-child(even) {
        margin: 0.25em 0;
      }

      .svg-icon {
        margin: auto;
      }
    }
  }

  .category {
    color: #1475E1;

    &.active {
      color: white;
    }
  }

  .active {
    color: white;
  }

  .nav-button {
    padding: 0.5em 1em;

    .label {
      margin-left: 0.5em;
    }
  }

  .dropdown-content .nav-button {
    justify-content: space-between;
    padding: 0.25em 1em;
  }
}
</style>
