<template>
  <div
    class="js-nav-height-calc lg:hidden z-40 -top-[1px] left-0 w-full flex items-center px-3 py-2 bg-black shadow-lg shadow-black/20"
    :class="[
      isMobileNavOpen || ['fullscreen', 'minified'].includes(chatState) ? 'fixed' : 'sticky',
      { 'hidden' : isMobileNavHidden },
      { 'top-[22px]': (!isMobileNavHidden && isMobileNavOpen && isUserLogged) }
    ]"
  >
    <NavigationBurgerButton
      :is-mobile-nav-open="isMobileNavOpen"
      @click.prevent.stop="toggleNavState"
    />

    <div class="ml-3 flex-1">
      <NuxtLink
        :to="logoLink"
        :replace="route.fullPath.includes('sports')"
        class="flex max-w-[138px] items-center align-center"
      >
        <img
          src="~/assets/img/logos/MetaWin-logo-white.svg"
          alt="MetaWin logo"
          width="165"
          height="24"
          class="inline-block w-full"
        >
        <img
          v-if="showHalloweenLogo"
          src="~/assets/img/promos/halloween-animation.gif"
          alt="Halloween"
          width="35"
          height="35"
          class="inline-block"
        >
        <img
          v-if="showChristmasLogo"
          src="~/assets/img/promos/christmas-animation.gif"
          alt="Halloween"
          width="40"
          height="40"
          class="inline-block absolute bottom-auto -top-1 left-[48%] -translate-x-2/4 mx-auto"
        >
        <img
          v-if="showChristmasLogo"
          src="~/assets/img/promos/snow.gif"
          alt="Halloween"
          width="150"
          height="87"
          class="absolute inset-0 inline-block"
        >
      </NuxtLink>
    </div>

    <div class="flex ml-auto min-h-[42px]">
      <BalanceMainNav v-if="isUserLogged"/>

      <NuxtLink
        v-if="isUserLogged && userProfileUrl"
        :to="userProfileUrl"
        :replace="route.fullPath.includes('sports')"
        class="ml-2 3xs:ml-3 my-auto flex"
      >
        <img
          class="rounded-full w-8 h-8 object-cover"
          :class="{ 'ring-2 ring-red-600 ring-opacity-80': isSalonRougePlayer }"
          :src="userData?.avatarUrl"
          alt="Your avatar"
          decoding="async"
        >
      </NuxtLink>

      <ButtonConnect
        v-if="!isUserLogged"
        theme="loadmore"
        size="xs"
        class="text-xs 2xs:text-sm font-bold my-auto flex"
        text="Sign In"
      />

      <ButtonConnect
        v-if="!isUserLogged"
        theme="primary"
        size="xs"
        class="text-xs 2xs:text-sm my-auto flex"
        text="Register"
      />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useUiStore } from '@/store/ui';

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const userStore = useUserStore();
const {
  userData,
  userProfileUrl,
  isSalonRougePlayer,
} = storeToRefs(userStore);

const uiStore = useUiStore();
const {
  isMobileNavOpen,
  chatState,
  isMobileNavHidden,
} = storeToRefs(uiStore);
const {
  setNavState,
  resizeHandler,
} = uiStore;

const route = useRoute();

const showHalloweenLogo = ref(false);
const showChristmasLogo = ref(false);

const logoLink = computed(() => {
  if (route.name === 'live-games-id') {
    return '/live-games';
  }
  if (route.name === 'games-id' || route.name === 'minigames-id') {
    return '/games';
  }

  return '/';
});

function toggleNavState() {
  if (window.innerWidth < 1024) {
    chatState.value = 'hidden';
  }

  isMobileNavOpen.value = !isMobileNavOpen.value;
  setNavState(isMobileNavOpen.value ? 'full' : 'hidden');
  useBodyScroll(!isMobileNavOpen.value);
}

onMounted(() => {
  resizeHandler();
});
</script>
