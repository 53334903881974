<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div class="flex items-stretch">
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        :class="{ 'active': category.isActive || (!dropdownEnabled && isProfilePath) }"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          height="20"
          width="20"
          class="icon"
        />
        <div v-if="navState === 'full'" class="label">
          {{ category.label }}
        </div>
        <NavigationToolTip :label="category.label"/>
      </button>
      <button
        v-if="dropdownEnabled"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="isCollapsed = !isCollapsed"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180': !isCollapsed }"
        />
      </button>
    </div>
    <div v-if="!isCollapsed && dropdownEnabled" class="dropdown-content mt-1">
      <template
        v-for="item in userMenuItems"
        :key="item.icon"
      >
        <button
          v-if="!item.disabled"
          class="flex items-center nav-button nav-sub rounded-md w-full relative"
          :class="{ 'active': item.isActive }"
          size="2xs"
          @click.prevent.stop="item.action()"
        >
          <SvgIcon
            :icon="item.icon"
            height="20"
            width="20"
            class="icon"
          />
          <div v-if="navState === 'full'" class="label">
            {{ item.label }}
          </div>
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useUserStore } from '@/store/user';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, showRedeemModal, } = storeToRefs(uiStore);
const { closeMobileNav, } = uiStore;

const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const profilePathsRegex = /notifications|account|wallet|history/g;
const isCollapsed = ref(false);

const dropdownEnabled = computed(() => navState.value === 'full');
const isProfilePath = computed(() => profilePathsRegex.test(route.path));
const category = computed(() => {
  return {
    icon: 'avatar',
    label: 'My Profile',
    isActive: route.path.includes(userData.value?.displayName),
    action: () => sportsBettingRedirect(`/${userData.value.displayName}/`),
  };
});
const userMenuItems = computed(() => [
  {
    icon: 'notifications',
    label: 'Notifications',
    isActive: route.path.includes('notifications'),
    action: () => sportsBettingRedirect('/notifications/'),
  },
  {
    icon: 'wallet',
    label: 'Wallet',
    isActive: route.path.includes('wallet'),
    action: () => sportsBettingRedirect('/wallet/'),
  },
  {
    icon: 'rain',
    label: 'Make it Rain',
    isActive: route.path.includes('account/tip'),
    disabled: !userData.value?.tags?.includes('TipsEnabled'),
    action: () => sportsBettingRedirect('/account/tip/'),
  },
  {
    icon: 'rocket',
    label: 'Boost Code',
    isActive: route.path.includes('notifications/?tab=bonuscode)'),
    action: () => {
      closeMobileNav();
      showRedeemModal.value = true;
    },
  },
  {
    icon: 'clockRewind',
    label: 'History',
    isActive: route.path.includes('history'),
    action: () => sportsBettingRedirect('/history/'),
  },
  {
    icon: 'settings',
    label: 'Settings',
    isActive: route.path.includes('account'),
    action: () => sportsBettingRedirect('/account/'),
  },
]);
</script>

<style lang="scss" scoped>
.navigation-section {
  
  &.collapsed {
    background: unset;

    .nav-button {
      padding: 9px 10px;

      &:nth-child(even) {
        margin: 0.25em 0;
      }

      .svg-icon {
        margin: auto;
      }
    }
  }

  .nav-button {
    padding: 0.5em 1em;

    .label {
      margin-left: 0.5em;
    }
    
    &.nav-sub {
      padding-left: 1.75em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
}
</style>