<template>
  <div
    :key="countLabel"
    class="text-white rounded-full text-3xs py-0.5 px-1.5 min-w-[16px] text-center animate-scale-up-once"
    :class="themeComp"
  >
    <span>{{ countLabel }}</span>
    <span v-if="suffixText" class="ml-0.5">{{ suffixText }}</span>
  </div>
</template>

<script setup>
const props = defineProps({
  count: {
    type: Number,
    required: true,
  },
  suffixText: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: null,
  },
});

const countLabel = computed(() => props.count > 99 ? '99+' : props.count);
const themeComp = computed(() => props.theme || 'bg-blue-500');
</script>
