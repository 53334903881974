<template>
  <template v-if="initiated && currentMessage">
    <div
      v-element-visibility="onVisibilityChange"
      class="activity-ticker bg-slate-900 overflow-hidden px-1 text-white flex items-center justify-center flex-wrap text-ellipsis relative py-0.5 h-[24px]"
      :class="{
        'pl-[--nav-left-width]': !isMobileNavOpen && navState === 'full',
        'pl-[--nav-left-mini-width]': navState === 'mini',
        'xl:pr-[--chat-desktop-width]': !['hidden', 'disabled'].includes(chatState),
        'pr-[--chat-desktop-width]': ['fullscreen'].includes(chatState),
        'z-30' : showAtFront,
        'sticky top-0' : alterativeLayout
      }"
    >
      <transition name="slidedown-fade-fast" mode="out-in">
        <button
          :key="currentMessage.id"
          type="button"
          class="flex text-center md:line-clamp-1 text-scaled-to-container animate-slide-down-fade-in-fast truncate md:!text-sm cursor-pointer"
          @click.prevent.stop="messageAction"
          v-html="currentMessage.text"
        />
      </transition>
    </div>
  </template>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import uuid from 'uuid';
import { vElementVisibility } from '@vueuse/components';

import { useAuthStore } from '@/store/auth';
import { useWebsocketStore } from '@/store/websocket';
import { useUiStore } from '@/store/ui';
import { useMinigamesStore } from '@/store/minigames';
import { useUserStore } from '@/store/user';
import { useUserAuth } from '~/composables/useUserAuth';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

const props = defineProps({
  mode: {
    type: String,
    default: null,
  },
  slow: {
    type: Boolean,
    default: false,
  },
  showAtFront: {
    type: Boolean,
    default: false,
  },
  alterativeLayout: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  chatState,
  navState,
  isMobileNavOpen,
} = storeToRefs(uiStore);
const {
  openTxModal,
} = uiStore;

const authStore = useAuthStore();
const {
  isUserLogged,
  wallet,
} = storeToRefs(authStore);

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const websocketStore = useWebsocketStore();
const {
  subscribe,
  unsubscribe,
} = websocketStore;

const minigamesStore = useMinigamesStore();
const {
  openGameDirect,
} = minigamesStore;

const { $getNFTTokenNumber, $round, $sanity, $sanityBlocksToHtml, $rollbar, } = useNuxtApp();
const router = useRouter();
const route = useRoute();
const { handleOpenRegisterModal, } = useUserAuth();

const rotationPolling = ref(null);
const initiated = ref(false);
const staticMessages = ref([]);
const dynamicMessages = ref([]);
const currentMessage = ref(null);
const maxDynamicMessages = ref(20);
const lastStaticId = ref(null);
const activeGreeting = ref(0);
const isVisible = ref(false);
const greetings = ref([
  'Greetings, [username]! Welcome to Metawin',
  'Welcome, [username], to the world of Metawin',
  "We're thrilled to have you, [username], here at Metawin",
  'Step right up, [username], and welcome to Metawin!',
]);

const subscriptions = computed(() => {
  if (props.mode === 'InstantGamePage') {
    return [
      'Activity:Games',
    ];
  } else {
    return [
      'Activity:Sweepstakes',
      'Activity:Games',
      'Activity:Users',
      'Sweepstakes',
    ];
  }
});

await init();

async function init() {
  subscriptions.value.forEach((topic) => {
    subscribe(topic);
  });

  websocketStore.$onAction(({ name, args, }) => {
    if (document.visibilityState !== 'visible' || !isVisible.value) { return; }

    if (name === 'handleMessage') {
      if (args[0].type === 'Activity' && userData.value?.id) {
        useMessageHandler({ name, args, }, populateActivityMessage, 'Activity', 'GameWin');
        useMessageHandler({ name, args, }, populateActivityMessage, 'Activity', 'GameMultiWin');
      }
      if (args[0].type === 'Sweepstake:Winner') {
        useMessageHandler({ name, args, }, populateSweepstakeWinnerMessage, 'Sweepstake:Winner');
      }
    }
  });

  if (!initiated.value) { await feedInit(); }

  startPolling();
}

function startPolling() {
  clearInterval(rotationPolling.value);
  rotationPolling.value = setInterval(() => {
    setCurrentMessage();
  }, props.slow ? 2000 : 1000);
}

async function feedInit() {
  reset();
  await populateStaticMessages();
  setInitialMessage();
  initiated.value = true;
}

function setInitialMessage() {
  currentMessage.value = staticMessages.value.find(x => x.loggedIn === isUserLogged.value || x.loggedIn === undefined);
  lastStaticId.value = currentMessage.value?.id;
}

function setCurrentMessage() {
  if (!currentMessage.value) {
    setInitialMessage();
    return;
  }

  if (dynamicMessages.value.length > 0) {
    currentMessage.value = dynamicMessages.value.shift();
    return;
  }

  const availableItems = staticMessages.value.filter(x => (x.loggedIn === isUserLogged.value || x.loggedIn === undefined) && x.id !== lastStaticId.value);
  if (availableItems.length >= 1) {
    currentMessage.value = availableItems[Math.floor(Math.random() * availableItems.length)];
    lastStaticId.value = currentMessage.value.id;
  } else {
    setInitialMessage();
  }
}

function populateActivityMessage(payload) {
  // Ignore activity messages which don't fit the mode if mode was defined in props
  if (props.mode === 'InstantGamePage' && (payload?.type !== 'GameWin')) {
    return;
  }

  if (payload.type === 'SweepstakeEntered') {
    let tokenId = '';
    if (payload.data.prizes && payload.data.prizes.length > 0) {
      const item = payload.data.prizes[0].item;
      if (item.type === 'NFT') {
        tokenId = $getNFTTokenNumber(item.name, item.tokenId);
      }
    }

    dynamicMessages.value.push(buildMessage(
      payload.type,
      `<p class="w-full"> <strong class="text-yellow-500 uppercase tracking-wider">ENTRY</strong> ${payload.displayName} entered ${payload.data.name}${tokenId ? ` #${tokenId}` : ''}</p>`,
      false,
      payload
    ));
  }

  if (payload.type === 'UserCreated') {
    const messageText = greetings.value[activeGreeting.value].replace('[username]', payload.displayName);

    dynamicMessages.value.push(buildMessage(
      payload.type,
      `<p><strong class="text-cyan uppercase tracking-wider">CONNECT</strong> ${messageText}</p>`,
      false,
      payload
    ));
    activeGreeting.value = (activeGreeting.value === (greetings.value.length - 1)) ? 0 : activeGreeting.value + 1;
  }

  if (payload.type === 'GameBet') {
    const bet = $round(payload.data.bet, 6);
    const betText = bet ? `${bet} ${payload.data.currencyCode}` : 'a free spin';

    dynamicMessages.value.push(buildMessage(
      payload.type,
      `<p><strong class="text-orange-500 uppercase tracking-wider">ENTRY</strong> ${payload.displayName} entered ${payload.data.name} with ${betText}</p>`,
      true,
      payload
    ));
  }

  if (payload.type === 'GameWin') {
    dynamicMessages.value.push(buildMessage(
      payload.type,
      `<p><strong class="text-green-500 uppercase tracking-wider">WIN</strong> ${payload.displayName} won ${$round(payload.data.win, 6)} ${payload.data.currencyCode} on ${payload.data.name}</p>`,
      true,
      payload
    ));
  }

  if (payload.type === 'GameMultiWin') {
    for (const winner of payload.data.winners) {
      dynamicMessages.value.push(buildMessage(
        payload.type,
        `<p><strong class="text-green-500 uppercase tracking-wider">WIN</strong> ${winner.displayName} won ${$round(winner.amount, 6)} ${winner.currencyCode} on ${payload.data.name}</p>`,
        true,
        payload
      ));
    }
  }

  ensureMaxDynamicBuffer();

  if (currentMessage.value?.type === 'Static') {
    setCurrentMessage();
    startPolling();
  }
}

function populateSweepstakeWinnerMessage(payload) {
  let tokenId = '';
  if (payload.prize.type === 'NFT') { tokenId = payload.prize.tokenId; }

  dynamicMessages.value.push(buildMessage(
    'SweepstakeWin',
    `<p><strong class="text-green-500 uppercase tracking-wider">WIN</strong> ${payload.displayName} won ${payload.sweepstakeName}${tokenId ? ` #${tokenId}` : ''}</p>`,
    false,
    payload
  ));

  ensureMaxDynamicBuffer();
}

function ensureMaxDynamicBuffer() {
  if (dynamicMessages.value.length > maxDynamicMessages.value) { dynamicMessages.value.shift(); }
}

async function populateStaticMessages() {
  const messages = [];
  try {
    await $sanity.fetch('*[_type == "tannoid-messages"][0] { contentList }')
    .then((res) => {
      if (isArray(res.contentList) && res.contentList.length) {
        res.contentList.forEach((message) => {
          const messageContent = $sanityBlocksToHtml(message.content);
          const emphasizedMessage = message.payload === 'deposit' ? 'Deposit Now!' : `${message.higlightedMessage}`;
          messages.push(buildMessage(
            'Static',
            `<div class="flex">${messageContent}<span class="text-cyan ml-1">${emphasizedMessage}</span></div>`,
            true,
            message.payload === 'deposit' ? { deposit: true, } : { redirect: `/${message.payload}`, },
            message.loginRequired
          ));
        });
      }
    });
  } catch (error) {
    $rollbar.error('TannoyMessages fetchCmsData::', error);
  }
  staticMessages.value = messages;
}

function buildMessage(type, text, loginRequired, payload, loggedIn) {
  return {
    type,
    text,
    loginRequired,
    payload,
    id: uuid(),
    loggedIn,
  };
}

async function messageAction() {
  if (currentMessage.value?.loginRequired && !isUserLogged.value) {
    handleOpenRegisterModal();
    return;
  }

  switch (currentMessage.value?.type) {
    case 'Static':
      if (currentMessage.value?.payload?.redirect) {
        await navigateTo(currentMessage.value?.payload?.redirect);
      } else if (currentMessage.value?.payload?.deposit) {
        if (wallet.value) {
          openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[0]);
        } else {
          openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[1]);
        }
      }
      break;
    case 'SweepstakeEntered':
      await navigateTo(`/competition/${currentMessage.value?.payload?.data?.id}`);
      break;
    case 'UserCreated':
      await navigateTo(`/${currentMessage.value?.payload?.displayName?.toLowerCase()}`);
      break;
    case 'GameBet':
    case 'GameWin':
    case 'GameMultiWin':
      openGame(currentMessage.value?.payload?.data);
      break;
    case 'SweepstakeWin':
      await navigateTo(`/competition/${currentMessage.value?.payload?.sweepstakeId}`);
      break;
  }
}

function reset() {
  initiated.value = false;
  staticMessages.value = [];
  dynamicMessages.value = [];
  currentMessage.value = null;
}

function openGame(data) {
  if (!data) { return; }

  if (data.slug && data.provider !== 'Arena') {
    router.push(`/games/${data.slug}`);
  } else if (data.provider === 'Arena' || data.minigame === true) {
    openGameDirect(data, false, 'floating');
  }
}

function onVisibilityChange(state) {
  isVisible.value = state;
}

watch(
  () => isUserLogged.value,
  () => {
    reset();
    feedInit();
  }
);

onBeforeUnmount(() => {
  clearInterval(rotationPolling.value);
  rotationPolling.value = null;

  subscriptions.value.forEach((topic) => {
    unsubscribe(topic);
  });
});
</script>

<style scoped>
.text-scaled-to-container {
  font-size: 3.1vw;
  white-space: nowrap;
}

@media (min-width: 390px) {
  .text-scaled-to-container {
    font-size: 0.75rem;
  }
}
</style>
