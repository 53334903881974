<template>
  <div
    class="nav-socials-container w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div v-if="navState === 'full'" class="title w-full">
      Join our Community
    </div>
    <div
      class="socials flex"
      :class="navState === 'full' ? 'justify-between' : 'flex-col'"
    >
      <button
        v-for="social in socials"
        :key="social.icon"
        class="flex flex-1 items-center justify-center social-button rounded-md"
        size="2xs"
        @click.prevent.stop="social.action()"
      >
        <SvgIcon
          :icon="social.icon"
          height="20"
          width="20"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { handleOpenNewTab, } = useNavigation();

const socials = [
  {
    icon: 'discord',
    action: () => handleOpenNewTab('https://discord.gg/the-arena'),
  },
  {
    icon: 'twitter',
    action: () => handleOpenNewTab('https://twitter.com/Meta_Winners'),
  },
  {
    icon: 'telegram',
    action: () => handleOpenNewTab('https://t.me/metawinlfg'),
  },
];
</script>

<style lang="scss" scoped>
.nav-socials-container {
  background: #0F1115;
  margin-bottom: 0.5em;
  padding: 1em;

  &.collapsed {
    background: unset;
    padding: unset;

    .social-button {
      padding: 9px 10px;

      &:nth-child(even) {
        margin: 0.5em 0;
      }
    }
  }

  .title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 12px;
    color: #5F677C;
  }

  .social-button {
    padding: 10px;
    background: #1A1D26;
    color: #5F677C;

    &:nth-child(even) {
      margin: 0 1em;
    }

    &:hover {
      background: #5F677C;
      color: white;
    }
  }
}

.navigation-section {

  &.collapsed {
    background: unset;

    .nav-button {
      padding: 9px 10px;

      &:nth-child(even) {
        margin: 0.25em 0;
      }

      .svg-icon {
        margin: auto;
      }
    }
  }

  .nav-button {
    padding: 0.5em 1em;

    .label {
      margin-left: 0.5em;
    }
  }
}
</style>
