<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div
        :class="{ 'active': category.isActive }"
      class="flex items-stretch drop-down"
    >
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category"
        :class="{ 'cursor-not-allowed' : !userData?.displayName }"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="label text-white">
          {{ category.label }}
        </div>
        <NavigationToolTip :label="category.label"/>
      </button>
      <button
        v-if="dropdownEnabled"
        :class="{ 'cursor-not-allowed' : !userData?.displayName }"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="toggleDropDown"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180 text-white': !userNavIsCollapsed }"
        />
      </button>
    </div>
    <div v-if="(!userNavIsCollapsed) && dropdownEnabled" class="dropdown-content mt-1">
      <template
        v-for="item in userMenuItems"
        :key="item.icon"
      >
        <button
          v-if="!item.disabled"
          class="flex items-center nav-button nav-sub rounded-md w-full relative"
          :class="{ 'active': item.isActive }"
          size="2xs"
          @click.prevent.stop="item.action()"
        >
          <SvgIcon
            :icon="item.icon"
            class="text-[#CBCBCB] icon"
            height="20"
            width="20"
          />
          <div v-if="navState === 'full'" class="label">
            {{ item.label }}
          </div>
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useUserStore } from '@/store/user';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, showRedeemModal, userNavIsCollapsed, } = storeToRefs(uiStore);
const { closeMobileNav, } = uiStore;

const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const dropdownEnabled = computed(() => navState.value === 'full');
const category = computed(() => {
  return {
    icon: 'avatar',
    label: 'Profile',
    isActive: route.path.includes(userData.value?.displayName) || !userNavIsCollapsed.value,
    action: () => {
      userNavIsCollapsed.value = false;
      sportsBettingRedirect(`/${userData.value.displayName}/`);
    },
  };
});
const userMenuItems = computed(() => [
  {
    icon: 'notifications',
    label: 'Notifications',
    isActive: route.path.includes('notifications'),
    action: () => sportsBettingRedirect('/notifications/'),
  },
  {
    icon: 'wallet',
    label: 'Wallet',
    isActive: route.path.includes('wallet'),
    action: () => sportsBettingRedirect('/wallet/'),
  },
  {
    icon: 'rain',
    label: 'Make it Rain',
    isActive: route.path.includes('account/tip'),
    disabled: !userData.value?.tags?.includes('TipsEnabled'),
    action: () => sportsBettingRedirect('/account/tip/'),
  },
  {
    icon: 'referrals',
    label: 'Referrals',
    isActive: route.path.includes('referrals'),
    action: () => sportsBettingRedirect('/referrals'),
  },
  {
    icon: 'rocket',
    label: 'Boost Code',
    isActive: route.path.includes('notifications/?tab=bonuscode)'),
    action: () => {
      closeMobileNav();
      showRedeemModal.value = true;
    },
  },
  {
    icon: 'clockRewind',
    label: 'History',
    isActive: route.path.includes('history'),
    action: () => sportsBettingRedirect('/history/'),
  },
  {
    icon: 'activity',
    label: 'Activity',
    hidden: false,
    isActive: route.path.includes('activity'),
    action: () => sportsBettingRedirect('/activity'),
  },
  {
    icon: 'medal',
    label: 'Winners',
    isActive: route.path.includes('winners'),
    hidden: false,
    action: () => sportsBettingRedirect('/winners'),
  },
  {
    icon: 'settings',
    label: 'Settings',
    isActive: route.path.includes('account'),
    action: () => sportsBettingRedirect('/account/'),
  },
]);

function toggleDropDown() {
  if (userData.value?.displayName) {
    userNavIsCollapsed.value = !userNavIsCollapsed.value;
  }
}
</script>
