<template>
  <div
    :class="[
      'bg-black h-full fixed p-4 top-0 left-0 hidden lg:block w-full lg:w-[--nav-left-mini-width] z-10 lg:z-20 transition-transform overflow-auto scrollbar-hide nav-left',
      { '-translate-x-full' : ['hidden', 'full'].includes(navState) },
    ]"
  >
    <NavigationBurgerButton
      class="mb-4"
      @click.prevent.stop="setNavState('full')"
    />

    <NavigationSectionOldCompetitions v-if="isUserLogged"/>
    <NavigationSectionOldGamesInstantWin v-if="isUserLogged"/>
    <NavigationSectionOldGamesLive v-if="isUserLogged"/>
    <NavigationSectionOldSports v-if="isUserLogged && sportsBettingProviders[0].enabled"/>
    <NavigationSectionOldCryptoFutures v-if="isUserLogged && nuxtApp.$config.public.TRADING_ENABLED"/>
    <Border v-if="isUserLogged"/>
    <NavigationSectionOldVip v-if="isUserLogged && nuxtApp.$config.public.SHOW_VIP"/>
    <Border v-if="isUserLogged"/>
    <NavigationSectionOldPromotions v-if="isUserLogged"/>
    <Border v-if="isUserLogged"/>
    <NavigationSectionOldCommunity v-if="isUserLogged"/>
    <Border v-if="isUserLogged"/>
    <NavigationSectionOldBridge v-if="isUserLogged"/>
    <Border v-if="isUserLogged"/>
    <template v-if="getConfig('ENV_NAME') !== 'Prod'">
      <NavigationSectionOldRaf v-if="isUserLogged"/>
      <Border v-if="isUserLogged"/>
    </template>
    <NavigationSectionOldUser v-if="isUserLogged"/>
    <Border v-if="isUserLogged"/>
    <NavigationSectionOldSupport v-if="isUserLogged"/>
    <Border v-if="isUserLogged"/>
    <NavigationSectionOldSocials/>
    <SwitchCompetitionValue
      id="collapsedCurrencyToggle"
      vertical
      hide-info-text
      small
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useAuthStore } from '@/store/auth';
import { useSportsBettingStore } from '@/store/sportsBetting';

const uiStore = useUiStore();
const {
  navState,
} = storeToRefs(uiStore);
const {
  setNavState,
} = uiStore;

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const nuxtApp = useNuxtApp();

const sportsBettingStore = useSportsBettingStore();
const { sportsBettingProviders, } = storeToRefs(sportsBettingStore);
</script>
