<template>
  <div
    class="w-full relative mb-4 px-3 md:px-3 md:my-5 md:text-center"
    :class="{'h-full px-5 text-center flex flex-col justify-center' : !guestAccess}"
  >
    <div class="animate-slide-down-fade-in06s text-center">
      <ButtonConnect
        size="sm"
        class="md:w-full mx-auto inline-block font-bold mb-2"
        theme="loadmore"
        text="Sign In"
      />

      <ButtonConnect
        size="sm"
        class="md:w-full mx-auto inline-block"
        text="Register"
      />

      <button
        v-if="false"
        class="p-3 block mt-4 mx-auto py-1 mb-2 text-white/60 hover:text-white font-semibold text-sm tracking-wider transition-all duration-100"
        @click.prevent.stop="showWhyConnectModal = true"
      >
        Why connect?
      </button>
    </div>

    <figure v-if="!guestAccess" class="absolute bottom-0 left-0 -z-10 w-full">
      <img src="~/assets/img/misc/chat-placeholder-blurred.png" class="select-none">
    </figure>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

defineProps({
  guestAccess: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  showWhyConnectModal,
} = storeToRefs(uiStore);
</script>
