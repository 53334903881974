<template>
  <div
    class="navigation-section !bg-transparent w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative mb-[5px] gold"
      :class="{ 'active': item.isActive }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        height="24"
        width="24"
        class="icon text-orange-dark"
      />
      <div
        v-if="navState === 'full'"
        class="label !font-bold inline-flex justify-between w-full"
      >
        <span>{{ item.label }}</span>
        <span v-if="item.leaderboardName && !loading" class="my-auto text-orange-dark h-3 text-xs">{{ days > 0 ? `${days}d` : 'Today' }}</span>
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();
const { $api, } = useNuxtApp();

const days = ref(0);
const loading = ref(true);

const items = computed(() => [
  {
    icon: 'flame-orange',
    color: 'gold',
    leaderboardName: 'WeeklyWagering',
    label: '$100k Weekly Race',
    showDays: true,
    isActive: route.path.includes('weekly-race'),
    action: () => sportsBettingRedirect('/weekly-race'),
  },
]);

async function fetchMonthlyDayCountdown() {
  loading.value = true;
  try {
    const response = await $api(`/leaderboard/schedule/${items.value[0].leaderboardName}/latest/1`, { method: 'GET', });

    const endTime = new Date(response[0].endTime).getTime();
    const now = new Date().getTime();
    const distance = endTime - now;

    days.value = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  } catch {
    // empty
  } finally {
    loading.value = false;
  }
}

fetchMonthlyDayCountdown();
</script>

<style lang="scss" scoped>
.gold {
  background: linear-gradient(90deg, rgba(255, 107, 0, 0.5) 0%, rgba(21, 23, 29, 0.25) 100%);
}

.gold .label {
  color: white;
}

.gold:hover {
  background: linear-gradient(90deg, rgba(255, 107, 0, 0.5) 0%, rgba(21, 23, 29, 0.25) 100%);
}

.gold:hover .label {
  color: #ff8a00;
}
</style>
