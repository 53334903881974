<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative"
      :class="{ 'active': item.isActive, }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        class="text-[#CBCBCB] icon"
        height="20"
        width="20"
      />
      <div
        v-if="navState === 'full'"
        class="label"
        :class="{ 'bold': item.bold }"
      >
        {{ item.label }}
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const items = computed(() => [
  {
    icon: 'tag',
    label: 'Promotions',
    bold: true,
    isActive: route.path.includes('promotions') && !route.path.includes('salon-rouge'),
    action: () => sportsBettingRedirect('/promotions'),
  },
]);
</script>

<style lang="scss" scoped>
.navigation-section {
  .nav-button {
    color: white;
    &.highlight {
      color: #FFC100;
    }
  }
}
</style>