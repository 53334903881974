<template>
  <div class="flex items-center">
    <SvgIcon
      width="24"
      height="24"
      icon="caret-up-fill"
      class="transition-transform"
      :class="{
        '!text-green': priceChange >= 0,
        '!text-red !rotate-180': priceChange < 0,
      }"
    />
    <div
      class="font-bold flex items-baseline tabular-nums"
      :class="toPriceChangeClass(priceChange)"
    >
      <div>{{ formattedPriceChange }}</div>
    </div>
  </div>
</template>

<script setup>
import { useTrading } from '@/composables/useTrading';

const props = defineProps({
  priceChange: {
    type: String,
    required: true,
  },
  price: {
    type: String,
    required: true,
  },
});

const { toPriceChangeClass, } = useTrading();

const formattedPriceChange = computed(() => {
  return parseFloat(props.price).toFixed(2);
});

</script>
