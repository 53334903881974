<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div class="flex items-stretch">
      <button
        class="flex-1 flex items-center nav-button rounded-md w-full relative category gap-1 h-[40px]"
        :class="{ 'active': category.isActive }"
        size="2xs"
        @click.prevent.stop="category.action()"
      >
        <SvgIcon
          :icon="category.icon"
          class="icon"
          height="20"
          width="20"
        />
        <div v-if="navState === 'full'" class="flex justify-between items-center w-full label">
          <div class=flex>
            <p class="font-bold">{{ category.label }}</p>
            <span v-if="category.badge" class="rounded-xl bg-blue-800 px-2 ml-1 text-xs content-center text-blue-300">{{ category.badge }}</span>
          </div>
          <PanelTradingTicker
            v-if="props.showPrice"
            :price-change="cyptoPrice.priceChange"
            :price="cyptoPrice.price"
            class="text-xs"
          />
        </div>
        <NavigationToolTip label="Crypto Futures"/>
      </button>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';
import { useWebsocketStore } from '@/store/websocket';

const route = useRoute();

const { handleBasicRedirectTo, } = useNavigation();

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const websocketStore = useWebsocketStore();
const { subscribe, } = websocketStore;

const cyptoPrice = ref(null);

const category = computed(() => {
  return {
    icon: 'HODL',
    label: 'HODL',
    badge: 'beta',
    isActive: route.path === '/trading/hodl/',
    action: () => handleBasicRedirectTo('/trading/hodl'),
  };
});

const props = defineProps({
  showPrice: {
    type: Boolean,
    default: false,
  },
})

async function fetchCryptoPrice() {
  await subscribe('Trading');

  websocketStore.$onAction(({ name, args, }) => {
    if (name !== 'handleMessage') {
      return;
    }

    const type = args[0].type;

    if (type === 'Trading:Price' && args[0].payload.code === 'HODL') {
      cyptoPrice.value = args[0].payload;
    }
  });
}

if (props.showPrice) {
  await fetchCryptoPrice();
}
</script>

<style lang="scss" scoped>
.navigation-section {
  .category {
    color: white;

    &.active {
      color: white;
    }
  }

  .active {
    color: white;
  }
}
</style>
