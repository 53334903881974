<template>
  <div
    class="md:hidden rounded-full flex items-center justify-center fixed right-3 cursor-pointer shadow-xl shadow-slate-900/30 transition-all w-8 h-8  bg-slate-600 z-30"
    :class="{
      'top-12': chatState === 'fullscreen',
      'top-1/2 mt-4' : chatState === 'minified',
    }"
    @click.prevent="emits('toggle-chat-size')"
  >
    <span
      class="icon-ico-up-pointer text-white text-xl transition-all"
      :class="{ 'rotate-180': chatState === 'fullscreen' }"
    />
  </div>
</template>

<script setup>
defineProps({
  chatState: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'toggle-chat-size',
]);
</script>
