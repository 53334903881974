<template>
  <div v-show="!forceHideChat">
    <transition name="fade-fast">
      <div
        v-if="!isUserLogged && !['hidden', 'disabled'].includes(chatState)"
        class="fixed bottom-0 right-0 z-30 h-full w-full md:w-[--chat-desktop-width] bg-slate-900/60 flex items-center justify-center backdrop-blur-sm px-3"
      >
        <ChatLogin guest-access/>
      </div>
    </transition>

    <ChatToggleSize
      v-if="widget && !isLoading && isUserLogged"
      :key="embeddedBreakpointView"
      :chat-state="chatState"
      :show-game-container="showGameContainer"
      @toggle-chat-size="toggleChatSize"
    />

    <ChatToggleDisplay
      v-if="showChat"
      :chat-state="chatState"
      :embedded-breakpoint-view="embeddedBreakpointView"
      :show-game-container="showGameContainer"
      :unseen-count="unseenCount"
      @toggle-chat-display="toggleChatDisplay"
    />

    <ChatIntercomLauncher
      v-if="intercomEnabled && showChat && isUserLogged"
      :chat-state="chatState"
      :embedded-breakpoint-view="embeddedBreakpointView"
      :show-game-container="showGameContainer"
    />

    <div
      class="fixed bottom-0 right-0 transition-all duration-100 w-full md:max-w-[--chat-desktop-width] z-20 bg-slate-900"
      :class="{
        'h-full': chatState === 'fullscreen' || chatState === 'embedded',
        'h-1/2 md:h-full mask-gradient-chat': !showGameContainer && chatState === 'minified',
        'h-1/2 md:h-full' : showGameContainer && chatState === 'minified',
        'h-full translate-x-full' : ['hidden', 'disabled'].includes(chatState) || forceHideChat,
      }"
    >
      <div id="chat-container" class="absolute h-full w-full"/>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useMinigamesStore } from '@/store/minigames';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { getConfig } from '@/utils/getConfig';
import { useIntercom } from '@/composables/useIntercom';

const uiStore = useUiStore();
const {
  chatState,
  showChat,
  navState,
  isMobileNavOpen,
} = storeToRefs(uiStore);

const minigamesStore = useMinigamesStore();
const {
  showGameContainer,
} = storeToRefs(minigamesStore);

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const userStore = useUserStore();
const {
  userData,
} = storeToRefs(userStore);

const route = useRoute();
const { $api, $config, $rollbar, } = useNuxtApp();
const { intercomEnabled, } = useIntercom();

const mq = inject('mq');
const widget = ref(null);
const isLoading = ref(false);
const unseenCount = ref(0);
const userDisplayName = ref(null);

// Determine enabling the embedded chat state (only available on desktop)
// All pages have chatDefaultState added to metadata. It determines the display of chat component
const embeddedBreakpointView = computed(() => {
  if (!route.meta.chatDefaultState) { return false; }
  return !!(mq.xlPlus && route.meta.chatDefaultState.desktop === 'embedded');
});
const forceHideChat = computed(() => {
  const bp = route?.meta?.chatDefaultState?.forceHideBreakpoint || null;
  return !!mq[bp];
});

function resetChat() {
  removeChat();
  checkPageForChat();
}

function removeChat() {
  const widgetbot = document.getElementById('widgetbot');
  if (widgetbot) { widgetbot.remove(); }
  widget.value = null;
  if (window.widgetbot?.embeds?.length) {
    window.widgetbot.embeds = [];
  }
}

async function fetchChatAuthToken() {
  try {
    const res = await $api('/chat/token', { method: 'GET', });
    return res;
  } catch (err) {
    $rollbar.error('fetchChatAuthToken error', err);
  }
}

// Determine if page needs the widgetbot loaded
async function checkPageForChat() {
  if (route.meta.chatDefaultState?.mobile !== 'disabled' || route.meta.chatDefaultState?.desktop !== 'disabled') {
    if (!widget.value) {
      await startWidgetbot();
    }
  } else {
    // Widget not needed
    presetChat();
  }
}

async function startWidgetbot() {
  removeChat();

  // get JWT of user
  let token = null;
  if (isUserLogged.value) {
    token = await fetchChatAuthToken();
  }

  userDisplayName.value = userData.value?.displayName || null;

  const container = document.getElementById('chat-container');
  const widget = document.createElement('widgetbot');
  widget.id = 'widgetbot';
  widget.setAttribute('server', $config.public.WIDGETBOT_SERVER);
  widget.setAttribute('channel', $config.public.WIDGETBOT_CHANNEL);
  widget.setAttribute('shard', 'https://emerald.widgetbot.io');
  widget.setAttribute('width', '100%');
  widget.setAttribute('height', '100%');

  if (token) { widget.setAttribute('token', token); }

  container.appendChild(widget);

  window.widgetbot.register();
  widget.value = document.getElementById('widgetbot');
  presetChat();

  // Increment unseen counter if chat was hidden or disabled
  widget.on('message', () => {
    if (chatState.value === 'hidden' || chatState.value === 'disabled') {
      unseenCount.value++;
    }
  });
}

function presetChat() {
  if (route.meta.chatDefaultState) {
    setChatState(route.meta.chatDefaultState);
  } else {
    setChatState({ mobile: 'disabled', desktop: 'disabled', });
  }
}

function setChatState(values) {
  if (!values) { return; }
  if (mq.xlPlus) {
    chatState.value = values.desktop;
  } else {
    chatState.value = values.mobile;
  }
}

function toggleChatDisplay() {
  // Used by the chat display toggle button
  if (window.innerWidth < 1024) {
    navState.value = 'hidden';
    isMobileNavOpen.value = false;
  }

  unseenCount.value = 0;
  if (chatState.value === 'hidden') {
    if (showGameContainer.value) {
      chatState.value = 'minified';
    } else {
      chatState.value = 'fullscreen';
    }
  } else {
    chatState.value = 'hidden';
  }
}

function toggleChatSize() {
  // Used by the chat resizer on mobile
  if (chatState.value === 'minified') {
    chatState.value = 'fullscreen';
  } else if (chatState.value === 'fullscreen') {
    chatState.value = 'minified';
  }
}

function widgetLogout() {
  if (widget.value) {
    widget.value.emit('logout');
  }
}

watch(
  () => isUserLogged.value,
  (newVal) => {
    if (!newVal) {
      widgetLogout();
    } else {
      resetChat();
    }
  }
);

watch(
  () => route,
  (newVal, oldVal) => {
    if (newVal.path !== oldVal.path) {
      checkPageForChat();
    }
  }
);

watch(
  () => embeddedBreakpointView.value,
  (val) => {
    if (val && (chatState.value === 'hidden' || chatState.value === 'minified')) {
      chatState.value = 'embedded';
    }
  }
);

watch(
  () => userData.value,
  (newVal, oldVal) => {
    // reset chat when user changes display name
    if (!oldVal || !newVal) { return; }
    if (newVal?.displayName !== userDisplayName.value) {
      resetChat();
    }
  },
  { deep: true, }
);

onMounted(() => {
  checkPageForChat();
});
</script>
